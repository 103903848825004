
import { reactive, ref } from "vue";
import { storesManageService } from "@/service";
import { useRoute, useRouter } from "vue-router";
import { Toast } from "vant";
import router from "@/router";
import { useUpload } from "@/composables";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const storeInfo = reactive<any>({
      data: [],
      result: [],
      async getOcReadyInfo() {
        try {
          const { data } = await storesManageService.toOcReady({
            stores_id: route.query.stores_id,
            oc_type: route.query.oc_type
          });
          storeInfo.data = data.data;
          storeInfo.result = data.data.assembly_json.map((item: any) => {
            let result: string | Array<any> | null = "";
            if (item.check_type === "check_checkbox") {
              result = [];
            }
            if (item.option?.length > 0) {
              item.option.forEach((element: any) => {
                if (element.is_default !== 1) return;
                if (Array.isArray(result)) {
                  result.push(element.key);
                  return;
                }
                result = element.key;
              });
            }
            const o: any = {
              sort: item.sort,
              required: item.is_checked == 1,
              check_type: item.check_type,
              title: item.title,
              result
            };
            if (item.check_type === "check_assign") {
              o.src = "";
              o.popupShow = false;
              o.pic_ids = "";
              o.onClick = (item: any) => {
                item.src = "123123";
                item.popupShow = true;
              };
              o.onConfirm = (data: any, item: any) => {
                item.src = data.img_url;
                item.pic_ids = data.id;
              };
            }
            if (item.check_type === "check_pic") {
              o.pic_ids = [];
            }
            return o;
          });

          console.log(storeInfo.result);
        } catch (error) {
          console.log(error, "==");
        }
      }
    });

    storeInfo.getOcReadyInfo();

    const onSubmit = async () => {
      const find = storeInfo.result.find((item: any) => {
        if (!item.required) return false;
        if ("pic_ids" in item) {
          return item.pic_ids.length === 0;
        }
        if (Array.isArray(item.result)) {
          return item.result.length === 0;
        }
        return item.result === "";
      });
      if (find) {
        Toast.fail(`请填写${find.title}`);
        return;
      }
      const arr = storeInfo.result.map((item: any) => {
        const o: any = {
          sort: item.sort,
          result: item.result
        };
        if (item.check_type === "check_pic") {
          o.pic_ids = item.pic_ids.map((pic: any) => pic.res.id).join();
          o.result = o.pic_ids;
        }
        if (item.check_type === "check_assign") {
          o.pic_ids = item.pic_ids;
          o.result = o.pic_ids;
        }
        if (Array.isArray(item.result)) {
          o.result = o.result.join();
        }
        return o;
      });
      const { data } = await storesManageService.storesOc({
        stores_id: route.query.stores_id,
        oc_type: route.query.oc_type,
        result_json: JSON.stringify(arr),
        iot_params: storeInfo.data.iot_params ? JSON.stringify(storeInfo.data.iot_params) : ""
      });
      router.back();
    };

    const { upload } = useUpload();

    return {
      storeInfo,
      onSubmit,
      upload
    };
  }
};
